import React, { useState } from 'react';
import LitStructuredText from '../generic/LitStructuredText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { getContractDetails, getRoundedNumber } from '../../data/Products';

const PackageTiles = ({
  chosenPackage,
  plan,
  promotion,
  onButtonClick,
  packageData,
}) => {
  const [moreInfo, setMoreInfo] = useState();

  const contractDetails = getContractDetails(plan.contract);
  const contractLength = contractDetails?.length ?? 12;
  const highlightedPackage = chosenPackage === plan.bundleCode;
  const applicablePromotion = promotion?.products[plan.bundleCode];

  const planPriceRounded = getRoundedNumber(plan?.price);
  const roundedPrice =
    applicablePromotion?.promoPackagePrice &&
    getRoundedNumber(applicablePromotion?.promoPackagePrice);
  const promoPeriod = applicablePromotion?.promoPeriod ?? 0;
  const discountPrice = roundedPrice ?? planPriceRounded;
  const promotionRoundedPrice = applicablePromotion?.promoPeriodPackagePrice
    ? getRoundedNumber(applicablePromotion?.promoPeriodPackagePrice)
    : discountPrice;

  const currentPackage = packageData.filter(
    (data) => data.packageName === plan.name && data,
  );

  const pageSlug = currentPackage[0].packagePageSlug;

  return (
    <div
      className={`Black-hover-highlight ${
        highlightedPackage ? 'highlighted-product' : 'unhighlighted-product'
      } ${moreInfo && 'open-product'}`}
    >
      {promoPeriod > 0 && (
        <div
          className={`card-promo-banner `}
          onClick={(e) =>
            onButtonClick(e, plan.bundleCode, plan.productCode, pageSlug)
          }
        >
          <p>
            Buy now for just £{promotionRoundedPrice}{' '}
            {promoPeriod > 1 && 'a month'} for the first{' '}
            {promoPeriod > 1 && promoPeriod} month{promoPeriod > 1 ? 's' : ''}
          </p>
        </div>
      )}
      <div
        className="product-card-inner"
        onClick={(e) =>
          onButtonClick(e, plan.bundleCode, plan.productCode, pageSlug)
        }
        style={{
          borderTop: `${!promoPeriod && 'none'}`,
        }}
      >
        <div className="card-header">
          <div className="card-header-product-description">
            <div className="card-header-product">
              <LitStructuredText data={currentPackage[0]?.packageTitle} basic />
              <span className="yellow-text">
                {contractLength} month contract
              </span>
            </div>
            <div className="card-header-short-description">
              <LitStructuredText
                data={currentPackage[0]?.shortDescription}
                basic
              />
            </div>
          </div>
          <div className="card-header-speeds-price">
            <div className="speeds">
              <LitStructuredText
                data={currentPackage[0]?.uploadDownloadSpeed}
              />
              {!moreInfo && (
                <div className="card-feature-headings">
                  <LitStructuredText
                    data={currentPackage[0]?.packageIncludesClosed}
                  />{' '}
                  <button onClick={() => setMoreInfo(!moreInfo)}>
                    <strong>+</strong>more...
                  </button>
                </div>
              )}
            </div>{' '}
            <div className="price">
              <p>£{discountPrice}pm</p>
            </div>
          </div>{' '}
        </div>{' '}
        {moreInfo && (
          <div className="card-body">
            <div className="card-body-features">
              <LitStructuredText data={currentPackage[0]?.longDescription} />
              {currentPackage[0]?.packageFeatures.map(({ text }) => {
                return <LitStructuredText data={text} />;
              })}
            </div>
            <div className="card-body-included">
              <LitStructuredText data={currentPackage[0]?.packageIncludes} />
              <div className="card-body-human-help-text">
                <LitStructuredText data={currentPackage[0]?.humanHelpText} />
              </div>
            </div>
          </div>
        )}
        <div className="product-card-button">
          <button
            type="button"
            onClick={(e) =>
              onButtonClick(e, plan.bundleCode, plan.productCode, pageSlug)
            }
            id={plan.productCode}
            aria-label={'Add to basket'}
            title={'Add to basket'}
          >
            <span
              className={`${
                moreInfo && highlightedPackage && 'open-highlighted-button'
              }`}
            ></span>
          </button>
        </div>
      </div>
      <div className="card-more-info-container">
        <button
          className="card-more-info"
          onClick={() => setMoreInfo(!moreInfo)}
        >
          {moreInfo ? 'Less Info' : 'More info'}
          <FontAwesomeIcon icon={faCaretRight} />
        </button>
      </div>
    </div>
  );
};

export default PackageTiles;
