import React from 'react';

const Toggle = ({
  name,
  onToggledValue,
  offToggledValue,
  isToggled,
  setIsToggled,
}) => {
  return (
    <div className="toggle-container">
      <p className="toggle-text">
        {isToggled ? onToggledValue : offToggledValue}
      </p>
      <div className={`toggle `} onClick={() => setIsToggled(!isToggled)}>
        <p className={`${isToggled && 'toggle-on'}`}>Yes</p>
        <p className={`${!isToggled && 'toggle-on'}`}>No</p>
      </div>
    </div>
  );
};

export default Toggle;
