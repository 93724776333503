import React from 'react';

const PackageSwitch = ({ productCategory, setProductCategory, buttonData }) => {
  const handleButtonClick = (event) => {
    setProductCategory(event.target.id);
  };

  return (
    <div className={`buttonSwitch`}>
      {buttonData?.map(({ buttonValue }) => {
        return (
          <button
            id={buttonValue}
            className={productCategory === buttonValue ? 'selected' : ''}
            onClick={handleButtonClick}
          >
            {buttonValue}
          </button>
        );
      })}
    </div>
  );
};

export default PackageSwitch;
