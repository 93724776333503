/* eslint-disable security/detect-object-injection */
import React, { useState, useRef, useEffect, useContext } from 'react';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import LitImage from '../components/images/LitImage';
import Modal from '../components/Modal';
import ActivityContext from '../context/ActivityContext';
import SimplePagePopUp from './SimplePagePopUp';
import Toggle from './Toggle';
import { Cookies } from 'react-cookie-consent';
import { ContactUsFormSubmitted } from '../data/Cookies';
import dataLayer from './DataLayer';
const ContactUsForm = ({
  formDashes,
  speechBubble,
  speechMarks,
  formSubmitted,
  overrideSubject,
  isHumanHelp,
}) => {
  const categories = [
    {
      subjectText: 'General',
      displayText: 'I have a general enquiry',
    },
    {
      subjectText: 'Recruitment',
      displayText: 'I would like to join your team',
    },
    {
      subjectText: 'Sales',
      displayText: 'I would like to become a customer',
    },
    {
      subjectText: 'Customer Services',
      displayText: 'I have a question for Customer Support',
    },
  ];
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [privacyOptIn, setPrivacyOptIn] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [contactno, setContactno] = useState('');
  const [message, setMessage] = useState('');
  const [alreadyACustomer, setAlreadyACustomer] = useState(false);
  const [postcode, setPostcode] = useState('');
  const [selectedCategory, setSelectedCategory] = useState(categories[0]);
  const [isExistingCustomer, setIsExistingCustomer] = useState();
  const [dialogShow, setDialogShow] = useState(true);
  const [animation, setAnimation] = useState(false);
  const { contactSubmitted, setContactSubmitted } = useContext(ActivityContext);
  const cookie = Cookies.get(ContactUsFormSubmitted);
  let validate;
  const { datoCmsSimpleWebsitePageModel } = imageQuery();

  const modal = useRef(null);
  const switchDialog = () => {
    const dialog = modal.current;
    setDialogShow((prevState) => !prevState);
    if (dialogShow) {
      dialog.showModal();
      setAnimation('open-modal');
    } else {
      setAnimation('close-modal');
      setTimeout(() => {
        dialog.close();
      }, 200);
    }
  };

  const handleSubmit = () => {
    if (privacyOptIn) {
      grecaptcha.ready(
        (validate = () => {
          var action = '/messaging/email/send';
          grecaptcha
            .execute(process.env.GATSBY_RECAPTCHA_SECRET_KEY, {
              action: action,
            })
            .then((token) => {
              const body = {
                EmailType: 0,
                To: '',
                From: `${name} <${email}>`,
                Subject: overrideSubject ?? selectedCategory.subjectText,
                Parameters: {
                  ExistingCustomer: isExistingCustomer || alreadyACustomer,
                  Name: name,
                  Email: email,
                  Contactno: contactno,
                  Postcode: postcode,
                  Message: message,
                  Subject: selectedCategory.subjectText,
                },
              };

              fetch(`${process.env.GATSBY_API_ADDRESS}${action}`, {
                method: 'post',
                body: JSON.stringify(body),
                headers: {
                  'Content-Type': 'application/json',
                  'Captcha-Token': `${token}`,
                  ApiKey: `${process.env.GATSBY_ADDRESS_API_KEY}`,
                },
              })
                .then((response) => {
                  if (response.ok) {
                    setContactSubmitted(true);
                    dataLayer({
                      event: 'asked_for_human_help',
                      eventData: body,
                    });
                  } else {
                    navigate('/404');
                  }
                })
                .catch((error) => {
                  if (
                    process.env.NODE_ENV === 'development' ||
                    process.env.GATSBY_ENVIRONMENT === 'development'
                  )
                    console.log(error);
                  setError(true);
                  setErrorMessage(
                    'Oh no, something happened. Please Try again',
                  );
                });
            });
        }),
      );
    }
  };
  return (
    <>
      {!contactSubmitted && !cookie ? (
        <div className="form-container">
          {isHumanHelp && (
            <div className="form-heading">
              <h3>No jargon, just human help to guide you toward the light!</h3>
              <p>Leave your details and we will get in touch</p>
            </div>
          )}
          <div className="form-wrapper">
            <div className="form">
              {formDashes && (
                <div className="form-dashes">
                  <LitImage image={formDashes} alt="three black dashes" />
                </div>
              )}
              {speechMarks && (
                <div className="form-exclamation">
                  <LitImage
                    image={speechMarks}
                    alt="illustrated speech bubble with exclamation mark"
                  />
                </div>
              )}
              {speechBubble && (
                <div className="form-question">
                  <LitImage
                    image={speechBubble}
                    alt="illustrated speech bubble with question mark"
                  />
                </div>
              )}
              <form
                action="#"
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                {!isHumanHelp && (
                  <div className="form-checkbox-container column-span">
                    <input
                      type="checkbox"
                      className="checkbox"
                      name="existingCustomer"
                      checked={isExistingCustomer}
                      onChange={(e) => {
                        setIsExistingCustomer(e.target.checked);
                      }}
                    />{' '}
                    <p>Are you an existing customer?</p>
                  </div>
                )}
                <div className="form-input-container">
                  {!isHumanHelp && (
                    <div className="form-input-inner column-span">
                      <label htmlFor="category">Pick your enquiry type</label>
                      <div className="form-select-arrow">
                        <select
                          id="category"
                          name="category"
                          onChange={(e) => {
                            const index = e.target.selectedIndex;
                            setSelectedCategory(
                              categories[index] ?? {
                                subjectText: e.target.value,
                              },
                            );
                          }}
                        >
                          {categories
                            .map((category) => (
                              <option
                                key={category.subjectText}
                                value={category.displayText}
                              >
                                {category.displayText}
                              </option>
                            ))
                            .sort()}
                          {isExistingCustomer && (
                            <>
                              <option value="I would like to add on Lit Fibre Enhanced">
                                I would like to add on Lit Fibre Enhanced
                              </option>
                              <option value="I would like to add on a Lit WiFi device">
                                I would like to add on a Lit WiFi device
                              </option>
                            </>
                          )}
                        </select>{' '}
                      </div>
                    </div>
                  )}
                  <div className="error-message">
                    {error && selectedCategory && <p>{errorMessage}</p>}
                  </div>

                  <div className="form-input-inner column-1">
                    <label htmlFor="name">Full name*</label>
                    <input
                      type="text"
                      name="Name"
                      required
                      placeholder="Enter your name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div
                    className={`form-input-inner ${
                      isHumanHelp ? 'column-1' : 'column-3'
                    }`}
                  >
                    <label htmlFor="email">Email*</label>
                    <input
                      type="email"
                      name="Email"
                      required
                      pattern="^.+@.+\.[a-zA-Z]{2,63}$"
                      maxLength="250"
                      placeholder="Enter your email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div
                    className={`form-input-inner ${
                      isHumanHelp ? 'column-3' : 'column-1'
                    }`}
                  >
                    <label htmlFor="Contactno">Telephone*</label>
                    <input
                      type="text"
                      name="Contactno"
                      required
                      placeholder="Enter your telephone"
                      value={contactno}
                      onChange={(e) => setContactno(e.target.value)}
                    />
                  </div>
                  {!isHumanHelp && (
                    <div className="form-input-inner column-3">
                      <label htmlFor="Postcode">Your postcode*</label>
                      <input
                        type="text"
                        required
                        name="Postcode"
                        placeholder="Enter your postcode"
                        value={postcode}
                        onChange={(e) => setPostcode(e.target.value)}
                      />
                    </div>
                  )}

                  {isHumanHelp ? (
                    <div className="form-input-inner column-span">
                      <label htmlFor="Message">
                        Anything else we need to know?
                      </label>
                      <textarea
                        minLength={10}
                        name="Message"
                        rows={3}
                        value={message}
                        placeholder="Enter your message"
                        onChange={(e) => setMessage(e.target.value)}
                      />
                    </div>
                  ) : (
                    <div className="form-input-inner column-span">
                      <label htmlFor="Message">Your message*</label>
                      <textarea
                        minLength={10}
                        name="Message"
                        rows={11}
                        value={message}
                        required
                        placeholder="Enter your message"
                        onChange={(e) => setMessage(e.target.value)}
                      />
                    </div>
                  )}
                </div>
                {isHumanHelp && (
                  <Toggle
                    offToggledValue={'I already have a Lit Fibre connection'}
                    onToggledValue={'I already have a Lit Fibre connection'}
                    isToggled={alreadyACustomer}
                    setIsToggled={setAlreadyACustomer}
                  />
                )}
                <div className="form-checkbox-container column-span">
                  <input
                    className="checkbox"
                    type="checkbox"
                    required
                    name="terms-and-conditions"
                    checked={privacyOptIn}
                    onChange={(e) => {
                      setPrivacyOptIn(e.target.checked);
                    }}
                  />
                  <p>
                    By providing my details. I consent to Lit Fibre processing
                    and storing this information in accordance with it’s{' '}
                    <button onClick={switchDialog}>Privacy Policy</button> and
                    using these details to send me information about Lit Fibre’s
                    services. I understand that I may change my mind and
                    unsubscribe at any time.{' '}
                  </p>
                  <Modal
                    animation={animation}
                    dialogShow={dialogShow}
                    modal={modal}
                    switchDialog={switchDialog}
                  >
                    <div className="modal-simple-website">
                      <SimplePagePopUp
                        simpleModularContent={
                          datoCmsSimpleWebsitePageModel.simpleModularContent
                        }
                        title={datoCmsSimpleWebsitePageModel.title}
                        slug={datoCmsSimpleWebsitePageModel.slug}
                      />
                    </div>
                  </Modal>
                </div>

                <div className="form-single-button-container">
                  <button
                    type="button"
                    className="button"
                    onClick={handleSubmit}
                  >
                    {isHumanHelp ? 'Get human help' : 'Submit'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      ) : (
        <div className="form-wrapper Centre" style={{ width: '100%' }}>
          <div className="form White" style={{ width: '100%' }}>
            <div className="form-submitted">
              <div className="form-submitted-image">
                <LitImage image={formSubmitted} alt="Icon" />
              </div>
              <h5>Great, your enquiry has been sent!</h5>
              <p>We will be in touch very soon.</p>
              <p style={{ paddingTop: '1em' }}>
                Call backs will only happen during our office hours of Mon-Fri,
                9:00-5:30pm.
              </p>
            </div>{' '}
            <button
              onClick={() => setContactSubmitted(false)}
              className="button"
              type="button"
            >
              Submit another query
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default ContactUsForm;
const imageQuery = () =>
  useStaticQuery(graphql`
    {
      datoCmsSimpleWebsitePageModel(slug: { eq: "legal/privacy-policy" }) {
        title
        slug
        simpleModularContent {
          ...ModularSimpleHero
          ...ModularParagraph
          ...ModularSimpleContent
        }
      }
    }
  `);
