export const RIFieldsLive = {
  url: 'https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8',
  oid: '00D4L000000CplT',
  uprn: '00N4L000000Q40C',
  leadSource: 'lead_source',
  street: 'street',
  city: 'city',
  zip: 'zip',
  state: 'state',
  country: 'country',
  firstName: 'first_name',
  lastName: 'last_name',
  email: 'email',
  phone: 'phone',
  company: 'company',
  hearAboutUs: '00N4L000006YWi6',
  provider: '00N4L000000Pymm',
  endDate: '00N4L000000Pyml',
  communicationPreference: '00N4L000003AE5u',
  orderable: '00N8d000008SGgt',
  message: '00N8d00000KxqE0',
  ambassador: '00N8d00000KxqDz',
  otherSource: '00N8d00000KxqE1',
  region: '00N8d00000Kxwgi',
  referralCode: '00N8d00000Ky0qX',
  latitude: '00N8d00000VAdeW',
  longitude: '00N8d00000VAdeX',
  salesNotes: '00N8d00000VDQYS',
  followUpDate: '00N8d00000VDQYR',
  leadType: '00NTv000002lenF',
  humanHelpMessage: '00N8d00000KxqE0',
  alreadyCustomer: '00NTv000004dccb',
};

export const RIFieldsUAT = {
  ...RIFieldsLive,
  url: 'https://test.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8',
  oid: '00D8E000000IE5R',
  uprn: '00N4L000000Q40C',
  hearAboutUs: '00N4L000006YWi6',
  provider: '00N4L000000Pymm',
  endDate: '00N4L000000Pyml',
  communicationPreference: '00N4L000003AE5u',
  orderable: '00N8d000008SGgt',
  message: '00N8d00000KxqE0',
  ambassador: '00N8d00000KxqDz',
  otherSource: '00N8d00000KxqE1',
  region: '00N8d00000Kxwgi',
  referralCode: '00N8d00000Ky0qX',
  latitude: '00N8d00000VAdeW',
  longitude: '00N8d00000VAdeX',
  salesNotes: '00N8E00000Ec1kl',
  followUpDate: '00N8E00000Ec1kv',
  leadType: '00NUE000001MMHR',
  humanHelpMessage: '00N8d00000KxqE0',
  alreadyCustomer: '00NUE000001vlT3',
};
