import React, { useState, useEffect, useRef } from 'react';
import flagService from '../services/FlagService';
import { RIFieldsLive, RIFieldsUAT } from '../data/SalesforceFields';
const { townNamesMatch } = require('../util/util');
import { Cookies } from 'react-cookie-consent';
import { navigate, useStaticQuery, graphql } from 'gatsby';
import { validateEmail } from '../services/EmailService';
import { ReferralCodeCookie } from '../data/Cookies';
import FacebookPixelEvents from '../services/FacebookPixelEvents';
import { SendFacebookConversionsEvent } from '../services/FacebookConversionsApiService';
import useSentryError from '../hooks/useSentryError';
import { ContactCookie } from '../data/Cookies';
import SimplePagePopUp from './SimplePagePopUp';
import Modal from './Modal';
import Toggle from './Toggle';
const cookieName = 'litfibre-address';

const RegisterInterestForm = ({
  selectedAddress,
  setSelectedAddress,
  landingPages,
  region,
  forceReferralCode,
  defaultSource,
  leadSource,
  showSalesFields,
  isModularBlock,
  successPath,
  isHumanHelp,
  alreadyACustomer,
}) => {
  const [textarea, setTextarea] = useState();
  const [contractDate, setContractDate] = useState();
  const [message, setMessage] = useState('');
  const [followUpDate, setFollowUpDate] = useState();
  const [customRIPage, setCustomRIPage] = useState(undefined);
  const [defaultReferralCode, setDefaultReferralCode] = useState();
  const [defaultFormData, setDefaultFormData] = useState({});
  const [dialogShow, setDialogShow] = useState(true);
  const [animation, setAnimation] = useState(false);
  const modal = useRef(null);
  const { datoCmsSimpleWebsitePageModel } = imageQuery();
  const switchDialog = () => {
    const dialog = modal.current;
    setDialogShow((prevState) => !prevState);
    if (dialogShow) {
      dialog.showModal();
      setAnimation('open-modal');
    } else {
      setAnimation('close-modal');
      setTimeout(() => {
        dialog.close();
      }, 200);
    }
  };

  const sfFields =
    process.env.NODE_ENV === 'development' ||
    process.env.GATSBY_ENVIRONMENT === 'development'
      ? RIFieldsUAT
      : RIFieldsLive;
  const [sentryError, setSentryError] = useSentryError();

  useEffect(() => {
    // Executed on Mount
    let addressCookie = Cookies.get(cookieName);
    let defaultFormCookie = Cookies.get(ContactCookie);
    if (defaultFormCookie) {
      setDefaultFormData(JSON.parse(defaultFormCookie));
    }
    if (defaultFormData?.contractEndDate) {
      handleDate(defaultFormData.contractEndDate, setContractDate);
    }
    if (addressCookie) {
      let storedAddress = JSON.parse(atob(addressCookie));
      setSelectedAddress(storedAddress);

      if (landingPages) {
        const RISlug = getRIPageSlug(storedAddress);
        if (RISlug) navigate(`/get-lit/${RISlug}/register-interest`);

        // TODO: Improve on this. There is a plugin called Launchdarkly, which I'm not overly impressed with for what we need
        // For now, Environmental Variables will do. But it'd just be another website we need to sign up to.
        // Otherwise, we could drive this from datoCmsModel, or our own portal/API
        if (flagService.CustomRIPage) {
          const pageData = getCustomRIPage(storedAddress, landingPages);
          setCustomRIPage(pageData);
        }
      }
    } else {
      // No Cookie - Need to redirect.
      !isModularBlock && navigate('/get-lit');
    }

    const referralCookie = Cookies.get(ReferralCodeCookie);
    if (referralCookie && !defaultReferralCode)
      setDefaultReferralCode(atob(referralCookie));

    // Executed On Dismount
    return () => {};
  }, []);

  // Gets the Select Box and updates the option as to whether or not they are selected or not
  // this is a sneaky way of being able to use the Checkboxes as opposed to the multibox.
  const onPreferenceUpdate = (e) => {
    let { value } = e.target;
    let selectBox = document.getElementsByName(
      sfFields.communicationPreference,
    )[0];

    for (let index = 0; index < selectBox.length; index++) {
      const element = selectBox[index];

      if (element?.value == value) {
        element.selected = element.selected ? false : true;
      }
    }
  };

  // Takes the date and reformats it - appears Salesforce does not either like the format
  // the original box provided or was not being sent over correctly.
  const handleDate = (event, setter) => {
    const { value } = event.target;
    const displayValue = value.replace(
      /(\d{4})\-(\d{2})\-(\d{2}).*/,
      '$3/$2/$1',
    );
    setter(displayValue);
  };

  const getRIPageSlug = (address) => {
    let result = undefined;
    landingPages?.edges.forEach((townNode) => {
      if (
        address &&
        townNamesMatch(townNode.node.townName, address.postalTown)
      ) {
        result = townNode.node.slug;
      }
    });
    return result;
  };

  const getCustomRIPage = (address, landingPages) => {
    let customRIPage = undefined;

    if (address) {
      landingPages?.edges.forEach((edge) => {
        if (
          townNamesMatch(edge.node.townName, address.postalTown) ||
          townNamesMatch(edge.node.townName, address.addressLine2) ||
          townNamesMatch(edge.node.townName, address.addressLine3)
        ) {
          customRIPage = {
            returnURL: `${process.env.GATSBY_BASE_ADDRESS}/get-lit/success/${edge.node.slug}`,
          };

          return;
        }
      });
    }

    return customRIPage;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    for (const input of event.target) {
      if (input.name === sfFields.email) {
        if (!(await validateEmail(input.value))) {
          const anonValue = input.value ? input.value.split('@').pop() : 'null';
          const logMessage = `Failed to validate email for RI. Domain: ${anonValue}`;
          setSentryError(
            logMessage,
            undefined,
            { uprn: selectedAddress.uprn },
            'info',
          );

          input.setCustomValidity('Email is invalid');
          input.reportValidity();
          return;
        }
        break;
      }
    }

    if (!showSalesFields) {
      let facebookEventId = '';
      let firstName = '';
      let lastName = '';
      let phone = '';
      let email = '';

      for (const input of event.target) {
        switch (input.name) {
          case sfFields.firstName:
            firstName = input.value;
            break;
          case sfFields.lastName:
            lastName = input.value;
            break;
          case sfFields.phone:
            phone = input.value;
            break;
          case sfFields.email:
            email = input.value;
            break;
        }
      }

      if (typeof self !== 'undefined') {
        facebookEventId = self.crypto.randomUUID();
      }

      SendFacebookConversionsEvent({
        postcode: selectedAddress.postcode,
        eventName: 'Lead',
        city: selectedAddress.city,
        county: selectedAddress.county,
        firstName: firstName,
        lastName: lastName,
        phone: phone,
        email: email,
        eventId: facebookEventId,
        contentName: 'Register Interest',
      });
      FacebookPixelEvents.track(
        'Lead',
        {
          currency: 'GBP',
          delivery_category: 'home_delivery',
          content_category: 'product',
          content_name: 'Register Interest',
          email: email,
          phone_number: phone,
          click_id: facebookEventId,
        },
        facebookEventId,
      );
    }

    event.target.submit();
  };
  let returnPath = `${process.env.GATSBY_BASE_ADDRESS}/get-lit/success/`;
  if (successPath) {
    returnPath = `${process.env.GATSBY_BASE_ADDRESS}/${successPath}`;
  } else if (showSalesFields) {
    returnPath = `${process.env.GATSBY_BASE_ADDRESS}/internal/sales/register-interest/success/`;
  } else if (customRIPage) {
    returnPath = customRIPage.returnURL;
  }
  return selectedAddress ? (
    <div
      className={showSalesFields ? 'sales-form-container' : 'form-container'}
    >
      {isHumanHelp && (
        <div className="form-heading">
          <h3>No jargon, just human help to guide you toward the light!</h3>
          <p>Leave your details and we will get in touch</p>
        </div>
      )}
      <div className="form-wrapper">
        <form
          className={`${isModularBlock ? 'form' : 'form-black'}`}
          action={sfFields.url}
          method="POST"
          onSubmit={handleSubmit}
          autoComplete={showSalesFields && 'off'}
        >
          {/* <input type="hidden" name="debug" value={1} />
          <input
            type="hidden"
            name="debugEmail"
            value="ishbel.fahey-theaker@litfibre.com"
          /> */}
          <div className="form-input-container">
            <input type="hidden" name="oid" defaultValue={sfFields.oid} />
            <input type="hidden" name="retURL" defaultValue={returnPath} />
            <input
              type="hidden"
              name={sfFields.uprn}
              defaultValue={selectedAddress?.uprn}
            />
            <input
              type="hidden"
              name={sfFields.leadSource}
              defaultValue={leadSource ?? 'website'}
            />
            <input
              type="hidden"
              name={sfFields?.leadType}
              id={sfFields?.leadType}
              value="RI Lead"
              defaultValue="RI Lead"
            />
            <input
              type="hidden"
              name={sfFields.street}
              defaultValue={`${selectedAddress.addressLine1}${
                selectedAddress.addressLine2
                  ? `, ${selectedAddress.addressLine2}`
                  : ''
              }${
                selectedAddress.addressLine3
                  ? `, ${selectedAddress.addressLine3}`
                  : ''
              }`}
            />
            {region && (
              <input
                type="hidden"
                name={sfFields.region}
                id={sfFields.region}
                defaultValue={region}
              />
            )}
            <input
              type="hidden"
              name={sfFields.city}
              defaultValue={selectedAddress.postalTown}
            />
            <input
              type="hidden"
              name={sfFields.zip}
              defaultValue={selectedAddress.postcode}
            />
            <input
              type="hidden"
              name={sfFields.state}
              defaultValue={selectedAddress.county}
            />
            <input
              type="hidden"
              name={sfFields.country}
              defaultValue="United Kingdom"
            />
            <input
              id={sfFields.endDate}
              name={sfFields.endDate}
              value={contractDate}
              type="hidden"
            />
            <input
              id={sfFields.followUpDate}
              name={sfFields.followUpDate}
              value={followUpDate}
              type="hidden"
            />
            <input
              id={sfFields.orderable}
              name={sfFields.orderable}
              type="hidden"
              defaultValue={selectedAddress.orderable ? 'Yes' : 'No'}
            />
            <input
              type="hidden"
              name={sfFields.latitude}
              defaultValue={selectedAddress.latitude}
            />
            <input
              type="hidden"
              name={sfFields.longitude}
              defaultValue={selectedAddress.longitude}
            />
            <select
              id={sfFields.communicationPreference}
              multiple="multiple"
              name={sfFields.communicationPreference}
              title="Contact Preferences"
              hidden
            >
              <option value="Email">Email</option>
              <option value="Phone">Phone</option>
              <option value="Mail">Mail</option>
            </select>
            <div className="form-input-inner columm-1">
              <label>
                First Name<span>*</span>
              </label>
              <input
                type="text"
                placeholder="First Name"
                defaultValue={defaultFormData?.firstName}
                name={sfFields.firstName}
                role={showSalesFields && 'presentation'}
                required
              />
            </div>
            <div className="form-input-inner column-3">
              <label>
                Last Name<span>*</span>
              </label>
              <input
                type="text"
                placeholder="Last Name"
                defaultValue={defaultFormData?.lastName}
                name={sfFields.lastName}
                role={showSalesFields && 'presentation'}
                required
              />
            </div>
            <div className="form-input-inner column-1">
              <label>
                Email<span>*</span>
              </label>
              <input
                type="email"
                placeholder="Email address"
                name={sfFields.email}
                defaultValue={defaultFormData?.email}
                required
                onChange={(event) => event.target.setCustomValidity('')}
                role={showSalesFields && 'presentation'}
              />
            </div>
            <div className="form-input-inner column-3">
              <label>
                Contact Number<span>*</span>
              </label>
              <input
                type="tel"
                pattern="^(?:0|\+?44)(?:\d\s?){9,10}$"
                placeholder="Contact number"
                defaultValue={defaultFormData?.phoneNumber}
                name={sfFields.phone}
                required={!showSalesFields}
                role={showSalesFields && 'presentation'}
              />
            </div>
            {!isHumanHelp && (
              <div className="form-input-inner column-1  ">
                <label>How did you hear about us?</label>
                <div className="form-select-arrow">
                  <select
                    onChange={(event) => {
                      setTextarea(event.target.value);
                    }}
                    id={sfFields.hearAboutUs}
                    name={sfFields.hearAboutUs}
                    defaultValue={defaultSource ?? defaultFormData?.hearAboutUs}
                  >
                    <option value="">--None--</option>
                    <option value="Petrol pump">Petrol pump</option>
                    <option value="Roundabout">Roundabout</option>
                    <option value="Supermarket">Supermarket</option>
                    <option value="TV advert">TV advert</option>
                    <option value="Leaflet or Flyer through the post">
                      Leaflet or Flyer through the post
                    </option>
                    <option value="Email">Email</option>
                    <option value="Social Media (Facebook, Twitter, etc)">
                      Social Media (Facebook, Twitter, etc)
                    </option>
                    <option value="Search Engine (Google, Bing, etc)">
                      Search Engine (Google, Bing, etc)
                    </option>
                    <option value="Local event">Local event</option>
                    <option value="Football club">Football club</option>
                    <option value="Recommendation">Recommendation</option>
                    <option value="Lit Fibre representative at my door">
                      Lit Fibre Representative at my door
                    </option>
                    <option value="Lit Fibre engineer">
                      Lit Fibre engineer
                    </option>
                    <option value="Customer reviews e.g. Trustpilot">
                      Customer reviews e.g. Trustpilot
                    </option>
                    <option value="Comparison website">
                      Comparison website
                    </option>
                    <option value="Radio advert">Radio advert</option>
                    <option value="News">News</option>
                    <option value="Northgate School - Bishops Stortford">
                      Northgate School - Bishops Stortford
                    </option>
                    <option value="Clacton FC">Clacton FC</option>
                    <option value="Woodbridge Town FC">
                      Woodbridge Town FC
                    </option>
                    <option value="Other">Other</option>
                  </select>
                </div>
                {textarea === 'Other' && (
                  <div className="form-input-inner column-3">
                    <label>Other - Please specify:</label>
                    <textarea
                      maxLength={20}
                      className="message--textarea"
                      rows="3"
                      wrap="soft"
                      id={sfFields.otherSource}
                      name={sfFields.otherSource}
                    ></textarea>
                  </div>
                )}
              </div>
            )}
            {!isHumanHelp && (
              <div className="form-input-inner column-3  ">
                <label>Current Provider</label>
                <div className="form-select-arrow">
                  <select
                    id={sfFields.provider}
                    name={sfFields.provider}
                    title="Current Provider"
                    defaultValue={defaultFormData?.currentProvider}
                  >
                    <option value="">I don’t have a provider</option>
                    <option value="BT">BT</option>
                    <option value="Sky">Sky</option>
                    <option value="Virgin">Virgin</option>
                    <option value="TalkTalk">TalkTalk</option>
                    <option value="EE">EE</option>
                    <option value="Plusnet">Plusnet</option>
                    <option value="Post Office">Post Office</option>
                    <option value="Pop Telecom">Pop Telecom</option>
                    <option value="Vodafone">Vodafone</option>
                    <option value="My provider is not listed">
                      My provider is not listed
                    </option>
                  </select>
                </div>
              </div>
            )}
            {isHumanHelp && (
              <div className="form-input-inner column-span">
                <label htmlFor="Message">Anything else we need to know?</label>
                <textarea
                  minLength={10}
                  id={sfFields.humanHelpMessage}
                  name={sfFields.humanHelpMessage}
                  rows={3}
                  value={message}
                  placeholder="Enter your message"
                  onChange={(e) => setMessage(e.target.value)}
                />
                <input
                  name={sfFields.alreadyCustomer}
                  id={sfFields.alreadyCustomer}
                  value={alreadyACustomer ? 1 : 0}
                  hidden
                />
              </div>
            )}
            {!isHumanHelp && (
              <div className="form-input-inner column-1">
                <div className="form-input-date">
                  <label>Contract End Date</label>
                  <input
                    type="date"
                    placeholder="End date"
                    defaultValue={defaultFormData?.contractEndDate}
                    onChange={(e) => handleDate(e, setContractDate)}
                  />
                </div>
              </div>
            )}
            {!forceReferralCode && (
              <div
                className="form-input-inner"
                style={{ position: 'absolute' }}
              >
                <label type="hidden" style={{ opacity: 0 }}>
                  Referral Code
                </label>
                <input
                  type="hidden"
                  id={sfFields.referralCode}
                  name={sfFields.referralCode}
                  defaultValue={defaultReferralCode}
                  maxLength={6}
                />
              </div>
            )}
            {forceReferralCode && (
              <input
                type="hidden"
                id={sfFields.referralCode}
                name={sfFields.referralCode}
                defaultValue={forceReferralCode}
              />
            )}{' '}
            {showSalesFields && (
              <>
                {' '}
                <div className="form-input-inner column-3">
                  <div className="form-input-date">
                    <label>
                      Follow up date<span>*</span>
                    </label>
                    <input
                      type="date"
                      required
                      onChange={(e) => handleDate(e, setFollowUpDate)}
                      min={new Date().toLocaleDateString('fr-ca')}
                    />
                  </div>
                </div>
              </>
            )}
            <div className="column-1">
              <p className="form-contact-preferences">
                {isHumanHelp ? 'Marketing Preferences' : 'Contact Preferences'}
              </p>
              <div className="form-contact-flex-container">
                <div
                  className={`${
                    isModularBlock
                      ? 'form-checkbox-container'
                      : 'form-checkbox-container-white'
                  }`}
                >
                  <input
                    className={`${
                      isModularBlock ? 'checkbox' : 'checkbox-white'
                    }`}
                    type="checkbox"
                    value="Email"
                    defaultChecked={defaultFormData?.contactEmail}
                    onClick={(e) => onPreferenceUpdate(e)}
                  />
                  <p>E-mail</p>
                </div>
                <div
                  className={`${
                    isModularBlock
                      ? 'form-checkbox-container'
                      : 'form-checkbox-container-white'
                  }`}
                >
                  <input
                    className={`${
                      isModularBlock ? 'checkbox' : 'checkbox-white'
                    }`}
                    type="checkbox"
                    value="Phone"
                    defaultChecked={defaultFormData?.contactPhone}
                    onClick={(e) => onPreferenceUpdate(e)}
                  />
                  <p>Phone</p>
                </div>
                <div
                  className={`${
                    isModularBlock
                      ? 'form-checkbox-container'
                      : 'form-checkbox-container-white'
                  }`}
                >
                  <input
                    className={`${
                      isModularBlock ? 'checkbox' : 'checkbox-white'
                    }`}
                    type="checkbox"
                    value="Mail"
                    defaultChecked={defaultFormData?.contactMail}
                    onClick={(e) => onPreferenceUpdate(e)}
                  />
                  <p>Mail</p>
                </div>
              </div>
            </div>
            {showSalesFields && (
              <div className="form-input-inner column-3">
                <label>Notes</label>
                <textarea name={sfFields.salesNotes} id={sfFields.salesNotes} />
              </div>
            )}
            <div className="form-checkbox-container column-span">
              <div
                className={`${
                  isModularBlock
                    ? 'form-checkbox-container'
                    : 'form-checkbox-container-white'
                }`}
              >
                <input
                  className={`${
                    isModularBlock ? 'checkbox' : 'checkbox-white'
                  }`}
                  type="checkbox"
                  required
                  defaultChecked={defaultFormData?.Marketing}
                />
                <p>
                  By ticking the box I consent to Lit Fibre processing and
                  storing this information in accordance with its{' '}
                  <button onClick={switchDialog} type="button">
                    Privacy Policy
                  </button>{' '}
                  and using these details to send me information about Lit
                  Fibre's services. I understand that I may change my mind and
                  unsubscribe at any time. Your contact details will be used for
                  keeping them up to date with our progress and we will not be
                  shared with third parties.{' '}
                </p>
                <Modal
                  animation={animation}
                  dialogShow={dialogShow}
                  modal={modal}
                  switchDialog={switchDialog}
                >
                  <div className="modal-simple-website">
                    <SimplePagePopUp
                      simpleModularContent={
                        datoCmsSimpleWebsitePageModel.simpleModularContent
                      }
                      title={datoCmsSimpleWebsitePageModel.title}
                      slug={datoCmsSimpleWebsitePageModel.slug}
                    />
                  </div>
                </Modal>
              </div>
            </div>
          </div>
          <div className="order-journey-button-container">
            <button className="square-button-yellow">
              {isHumanHelp ? 'Get human help' : 'Register'}
            </button>
          </div>
        </form>
      </div>
    </div>
  ) : null;
};

export default RegisterInterestForm;
const imageQuery = () =>
  useStaticQuery(graphql`
    {
      datoCmsSimpleWebsitePageModel(slug: { eq: "legal/privacy-policy" }) {
        title
        slug
        simpleModularContent {
          ...ModularSimpleHero
          ...ModularParagraph
          ...ModularSimpleContent
        }
      }
    }
  `);
