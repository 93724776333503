class FlagService {
  constructor() {
    this.Trustpilot = EvaluateVariable(process.env.GATSBY_FEATURE_TRUSTPILOT);
    this.CustomRIPage = EvaluateVariable(
      process.env.GATSBY_FEATURE_CUSTOM_RI_PAGE,
    );
    this.SuggestedNews = EvaluateVariable(
      process.env.GATSBY_FEATURE_SUGGESTEDNEWS,
    );
    this.ReactOrderJourney = EvaluateVariable(
      process.env.GATSBY_FEATURE_REACT_JOURNEY,
    );
    this.AppointmentBooking = EvaluateVariable(
      process.env.GATSBY_FEATURE_APPOINTMENTS,
    );
    this.OrderCardMaintenance = EvaluateVariable(
      process.env.GATSBY_FEATURE_ORDER_MAINTENANCE,
    );
    this.OneTouchSwitch = EvaluateVariable(
      process.env.GATSBY_FEATURE_ONE_TOUCH_SWITCH,
    );
    this.NewsletterPopUp = EvaluateVariable(
      process.env.GATSBY_FEATURE_NEWSLETTER_POP_UP,
    );
    this.RegisterInterestPopup = EvaluateVariable(
      process.env.GATSBY_FEATURE_REGISTER_INTEREST_POP_UP,
    );
    if (
      process.env.NODE_ENV === 'development' ||
      process.env.GATSBY_ENVIRONMENT === 'development'
    )
      console.log(
        'Feature Service configured: ',
        this.Trustpilot,
        this.CustomRIPage,
        this.ReactOrderJourney,
        this.AppointmentBooking,
        this.OrderCardMaintenance,
        this.OneTouchSwitch,
        this.NewsletterPopUp,
        this.RegisterInterestPopup,
      );
  }
}

function EvaluateVariable(variable) {
  return variable?.toLowerCase() === 'true' ? true : false;
}

const flagService = new FlagService();

export default flagService;
