import React, { useEffect, useRef } from 'react';

const OrderJourneyBannerCarousel = ({ isSalesJourney }) => {
  const carousel = useRef(null);
  const carouselInner = useRef(null);
  const addScrollAnimation = () => {
    const setCarouselAnimation = carousel.current;
    const carouselInnerAnimation = carouselInner.current;
    setCarouselAnimation.setAttribute('data-animated', true);
    const carouselInnerArray = Array.from(carouselInnerAnimation.children);
    carouselInnerArray.forEach((item) => {
      const duplicatedItem = item.cloneNode(true);
      duplicatedItem.setAttribute('aria-hidden', true);
      carouselInnerAnimation.appendChild(duplicatedItem);
    });
  };
  useEffect(() => {
    if (
      !window.matchMedia('(prefers-reduced-motion: reduce)').matches &&
      !isSalesJourney
    ) {
      addScrollAnimation();
    }
  }, []);
  const bannerCarouselData = [
    {
      text: 'no mid-contract price hikes',
    },
    {
      text: 'simple switching',
    },
    {
      text: 'people-powered support',
    },
    {
      text: 'flexible packages',
    },
    {
      text: 'free installation',
    },
  ];
  return (
    <div
      className={`${
        !isSalesJourney
          ? 'order-journey-banner-carousel'
          : 'sales-order-journey-banner-carousel'
      }`}
      ref={carousel}
    >
      <div className="order-journey-banner-carousel-inner" ref={carouselInner}>
        {!isSalesJourney ? (
          bannerCarouselData.map(({ text }) => {
            return <p>{text}</p>;
          })
        ) : (
          <p style={{ margin: '0 auto' }}>Sales Journey</p>
        )}
      </div>
    </div>
  );
};

export default OrderJourneyBannerCarousel;
