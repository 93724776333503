import React, { useState, useContext, useEffect } from 'react';
import RegisterInterestForm from './RegisterInterestForm';
import AddressContext from '../context/AddressContext';
import { graphql, useStaticQuery } from 'gatsby';
import PostcodeSearch from './PostcodeSearch';
import { useLocation } from '@gatsbyjs/reach-router';
import { Cookies } from 'react-cookie-consent';
import { RegionalPromoCookie, InterestCookie } from '../data/Cookies';
const { townNamesMatch } = require('../util/util');
import LitImage from './images/LitImage';
const PostcodeRegisterInterestSwitch = ({
  successPath,
  leadSource,
  arrowsLeft,
  arrowsRight,
  isHumanHelp,
  isModularBlock,
  alreadyACustomer,
}) => {
  const [selectedAddress, setSelectedAddress] = useState(undefined);
  const [inputDisplayValue, setInputDisplayValue] = useState('');
  const { currentAddress, setCurrentAddress } = useContext(AddressContext);

  const formData = query();

  const location = useLocation();
  const inBuildTown = location.state?.inBuildTown;
  const networkCoverage = formData.allDatoCmsNetworkCoverageModel.nodes.find(
    (coverage) =>
      coverage.areaName &&
      inBuildTown &&
      townNamesMatch(coverage.areaName, inBuildTown),
  );
  const region = networkCoverage?.region;

  // const onChangeAddress = () => {
  //   Cookies.remove(InterestCookie);
  //   Cookies.remove(RegionalPromoCookie);

  //   setInputDisplayValue('');
  //   setSelectedAddress(undefined);
  //   setCurrentAddress(undefined);

  //   typeof window !== 'undefined' && window.scrollTo(0, 0);
  // };

  const showPostcode = (
    <>
      <div className="modular-p modular-text-center">
        <p>Please enter your postcode</p>
      </div>
      <div className="White">
        <div className="PostcodeSearchPrimary">
          {arrowsLeft && (
            <div className="arrows">
              {' '}
              <LitImage image={arrowsLeft} />
            </div>
          )}
          <PostcodeSearch
            placeholder="Check availability"
            selectedAddress={selectedAddress}
            setSelectedAddress={setSelectedAddress}
            inputDisplayValue={inputDisplayValue}
            setInputDisplayValue={setInputDisplayValue}
            isModularBlock={true}
          />
          {arrowsRight && (
            <div className="arrows">
              <LitImage image={arrowsRight} />
            </div>
          )}
        </div>
      </div>
    </>
  );
  const showRiForm = (
    <>
      <RegisterInterestForm
        selectedAddress={selectedAddress}
        setSelectedAddress={setSelectedAddress}
        region={region}
        isModularBlock={isModularBlock}
        successPath={successPath}
        leadSource={leadSource}
        isHumanHelp={isHumanHelp}
        alreadyACustomer={alreadyACustomer}
      />
      {/* {currentAddress && (
        <button
          onClick={onChangeAddress}
          className="button-blue check-address-button"
        >
          Or check another address
        </button>
      )} */}
    </>
  );

  return !currentAddress?.uprn ? showPostcode : showRiForm;
};
export default PostcodeRegisterInterestSwitch;
export const query = () =>
  useStaticQuery(graphql`
    {
      allDatoCmsNetworkCoverageModel {
        nodes {
          inBuild
          areaName
          available
          region
        }
      }
    }
  `);
