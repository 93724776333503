const validateEmail = async (email) => {
  if (typeof email !== 'string' || email.length === 0) return false;

  const apiKey = process.env.GATSBY_ZEROBOUNCE_KEY;
  if (!apiKey) return true;

  return fetch(
    `https://api.zerobounce.net/v2/validate?api_key=${apiKey}&email=${encodeURIComponent(
      email,
    )}`,
  )
    .then((response) => response.json())
    .then((result) => !result || result.error || result.status !== 'invalid')
    .catch((e) => {
      console.error(e);
      return true;
    });
};

export { validateEmail };
