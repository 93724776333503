import React, { useEffect, useState, useRef, useContext } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Modal from './Modal';
import ContactUsForm from './ContactUsForm';
import LitImage from './images/LitImage';
import ActivityContext from '../context/ActivityContext';
import dataLayer from './DataLayer';
import RegisterInterestForm from './RegisterInterestForm';
import { useLocation } from '@gatsbyjs/reach-router';
import PostcodeRegisterInterestSwitch from './PostcodeRegisterInterestSwitch';
const { townNamesMatch } = require('../util/util');
import Toggle from './Toggle';
import AddressContext from '../context/AddressContext';
const HumanHelpModal = ({
  isButton,
  isLink,
  isBlock,
  text,
  image,
  pathColour,
  setHumanHelpOpen,
}) => {
  const [dialogShow, setDialogShow] = useState(true);
  const [animation, setAnimation] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(undefined);
  const [alreadyACustomer, setAlreadyACustomer] = useState(false);
  const { currentAddress } = useContext(AddressContext);
  const { contactSubmitted, setContactSubmitted } = useContext(ActivityContext);
  const modal = useRef(null);
  const data = query();
  const location = useLocation();
  const inBuildTown = location.state?.inBuildTown;

  const networkCoverage = data.allDatoCmsNetworkCoverageModel.nodes.find(
    (coverage) =>
      coverage.areaName &&
      inBuildTown &&
      townNamesMatch(coverage.areaName, inBuildTown),
  );
  const region = networkCoverage?.region;

  const switchDialog = () => {
    const dialog = modal.current;
    setDialogShow((prevState) => !prevState);
    if (dialogShow) {
      setHumanHelpOpen && setHumanHelpOpen(true);
      dialog.showModal();
      setAnimation('open-modal');
      dataLayer({ event: 'considered_human_help' });
    } else {
      setAnimation('close-modal');
      setTimeout(() => {
        dialog.close();
      }, 200);
      setHumanHelpOpen && setHumanHelpOpen(false);
    }
  };

  useEffect(() => {
    const dialog = modal.current;
    contactSubmitted &&
      dialog &&
      setTimeout(() => {
        dialog.close();
        setAnimation('close-modal');
        setHumanHelpOpen && setHumanHelpOpen(false);
      }, 1500);
  }, [contactSubmitted]);

  const pathName =
    location.pathname === '/'
      ? location.pathname
      : location.pathname.replace(/^\/+/g, '');

  return (
    <div
      className="contact-us-link-container"
      style={{ display: `${isLink && 'inline'}` }}
    >
      <button
        type="button"
        onClick={() => switchDialog()}
        className={`${isButton && `square-button-${pathColour}`} ${
          isLink && `${pathColour}-button-underline`
        } ${pathColour} path`}
      >
        {image && (
          <div className="image">
            <LitImage image={image} />
          </div>
        )}
        {text}
      </button>
      <div
        className={`human-help-modal White ${
          currentAddress?.uprn ? 'modal-scroll' : 'modal-visible'
        }`}
      >
        <Modal
          animation={animation}
          dialogShow={dialogShow}
          modal={modal}
          switchDialog={switchDialog}
        >
          <Toggle
            offToggledValue={'I already have a Lit Fibre connection'}
            onToggledValue={'I already have a Lit Fibre connection'}
            isToggled={alreadyACustomer}
            setIsToggled={setAlreadyACustomer}
          />
          {alreadyACustomer ? (
            <div className="modular-p modular-text-center">
              <p>Contact us below for some human help</p>
              <Link to={'/contact-us'} className="square-button-yellow">
                Contact us
              </Link>
            </div>
          ) : (
            <PostcodeRegisterInterestSwitch
              isHumanHelp={true}
              region={region}
              isModularBlock={true}
              successPath={pathName}
              alreadyACustomer={alreadyACustomer}
              setAlreadyACustomer={setAlreadyACustomer}
              leadSource={'human help'}
            />
          )}
        </Modal>
      </div>
    </div>
  );
};

export default HumanHelpModal;
export const query = () =>
  useStaticQuery(graphql`
    {
      allDatoCmsNetworkCoverageModel {
        nodes {
          inBuild
          areaName
          available
          region
        }
      }
    }
  `);
