export const ReferralCodeCookie = 'litfibre-referral';

export const OtsCookie = 'litfibre-ots-id';

export const NewsletterFormCookie = 'litfibre-newsletter-form';

export const PurchaseEventCookie = 'litfibre-purchase';

export const ContactUsFormSubmitted = 'litfibre-contact-us';

export const ContactCookie = 'litfibre-contact-lead';

export const InterestCookie = 'litfibre-address';

export const RegionalPromoCookie = 'litfibre-promo';

export const PackageCookie = 'litfibre-package';

export const ContractLengthCookie = 'litfibre-contract-length';
