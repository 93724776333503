import { useCallback, useEffect, useState } from 'react';
import {
  captureMessage,
  captureException,
  setContext,
  setUser,
} from '@sentry/gatsby';
import { getAccessToken, salesJourneyAuth } from '../data/Authentication';
import {
  OrderJourneyTypes,
  getUserData,
  setBearerToken,
} from '../services/OrderService';
import { useMsal } from '@azure/msal-react';

const useSentryError = (context) => {
  const [error, setErrorState] = useState(undefined);
  const msal = useMsal();

  const setError = useCallback(
    async (message, exception, errorContext, severity = 'error') => {
      if (process.env.NODE_ENV === 'development')
        console.error(message, exception);

      if (context) setContext('Hook Context', sanitizeContext(context));
      else setContext('Hook Context', null);

      if (errorContext)
        setContext('Error Context', sanitizeContext(errorContext));
      else setContext('Error Context', null);

      const accessToken = await getAccessToken(msal, salesJourneyAuth, false);
      if (accessToken) {
        setBearerToken(accessToken);
        const userData = await getUserData(OrderJourneyTypes.sales)
          .then((response) => response.json())
          .catch(() => null);

        if (userData) setUser(userData);
      } else setUser(null);

      setContext('Error Display Message', { text: message });

      if (exception) captureException(exception);
      else captureMessage(message, severity);

      setErrorState(message);
    },
    [setErrorState, msal, context],
  );

  return [error, setError];
};

const sanitizeContext = (context) => {
  if (!context) return null;
  const result = {};
  Object.keys(context).forEach((key) => {
    if (key !== 'user') {
      const value = context[key];
      if (typeof value === 'object') result[key] = sanitizeContext(value);
      else result[key] = value;
    }
  });
  return result;
};

export default useSentryError;
