import React from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';

// Gets the current Promotion by checking the DateTime associated with Start and End Date
const GetCurrentPromotion = (addressPromo, promoUserType = null) => {
  const promotion = {
    code: 'dynamicPromo',
    defaultTerms: '/legal/sept22offer',
    extraTerms: [
      {
        title: '†Black Friday 2022 Terms and Conditions',
        url: '/legal/blackfriday2022',
        description:
          " - ends 04/12/2022, £300 saving is based on average offer price of Lit Fibre 500Mb (18 month contract) compared to BT's standard Full Fibre 500 price via bt.com, over an 18 month period.",
        contracts: ['18Months'],
      },
    ],
    displayName: '',
    banner: {
      active: true,
      text: (
        <>
          Offer extended for a limited time only! Order by 14th June to secure 3
          months free broadband then 40% off.<sup>*</sup>
        </>
      ),
      startDate: new Date('2023-06-06T11:00:00Z'),
      endDate: new Date('2023-06-14T10:00:00Z'),
    },

    hero: {
      active: true,
    },
    products: {},
  };

  const pricingData = usePricingData();

  const productNodes = pricingData?.allProduct?.nodes;

  if (productNodes) {
    const ignoreRegionalPromo =
      !addressPromo ||
      !productNodes.find(
        (productNode) => productNode.regionalPromo === addressPromo,
      );

    const ignoreUserType =
      !promoUserType ||
      !productNodes.find(
        (productNode) => productNode.userType === promoUserType,
      );

    productNodes.forEach((productNode) => {
      const {
        productCode,
        appliedPromotions,
        regionalPromo,
        userType,
        promoPeriod,
        catalogPackagePrice,
        catalogInstallPrice,
        promoPackagePrice,
        promoInstallPrice,
        promoExpiration,
        bundleCode,
      } = productNode;

      const today = new Date();
      const promoExpirationDate = promoExpiration
        ? new Date(promoExpiration)
        : undefined;

      if (promoExpirationDate && promoExpirationDate >= today) {
        if (!promotion.endDate) promotion.endDate = promoExpirationDate;
        else if (promoExpirationDate)
          promotion.endDate =
            promoExpirationDate < promotion.endDate
              ? promoExpirationDate
              : promotion.endDate;
      }

      const regionalPromoMatches =
        (ignoreRegionalPromo && !regionalPromo) ||
        addressPromo === regionalPromo;

      const userTypeMatches =
        (ignoreUserType && !userType) || promoUserType === userType;

      if (
        (promoPeriod > 0 ||
          promoPackagePrice !== catalogPackagePrice ||
          promoInstallPrice !== catalogInstallPrice) &&
        regionalPromoMatches &&
        userTypeMatches &&
        !bundleCode
      ) {
        promotion.products[productCode] = productNode;
      }
      if (
        (promoPeriod > 0 ||
          promoPackagePrice !== catalogPackagePrice ||
          promoInstallPrice !== catalogInstallPrice) &&
        regionalPromoMatches &&
        userTypeMatches &&
        bundleCode &&
        appliedPromotions.length > 0
      ) {
        promotion.products[bundleCode] = productNode;
      }
    });
  }
  return Object.keys(promotion.products).length > 0 ? promotion : undefined;
};

const usePricingData = () =>
  useStaticQuery(graphql`
    {
      allProduct {
        nodes {
          productCode
          appliedPromotions
          regionalPromo
          userType
          catalogInstallPrice
          catalogPackagePrice
          promoInstallPrice
          promoPackagePrice
          promoPeriodPackagePrice
          promoPeriod
          promoExpiration
          bundleCode
        }
      }
    }
  `);

export default GetCurrentPromotion;
