import React, { useState, useRef, useContext, useEffect } from 'react';
import ProductInfo from './ProductInfo';
import {
  NewsletterFieldsLive,
  NewsletterFieldsUAT,
} from '../data/NewsletterFields';
import Modal from './Modal';
import LitStructuredText from './generic/LitStructuredText';
import { doRecaptchaAction } from '../util/util';
import SimplePagePopUp from './SimplePagePopUp';
import useScreenSize from '../hooks/useScreenSize';
import ActivityContext from '../context/ActivityContext';
import { Link } from 'gatsby';
import { NewsletterFormCookie } from '../data/Cookies';
import { Cookies } from 'react-cookie-consent';
import { navigate } from 'gatsby';
const NewsletterForm = ({
  formHeading,
  legalTermsContent,
  backgroundColor,
  submitted,
  setSubmitted,
  isPopUp,
  leadSource = 'website',
  isProductPagePath,
}) => {
  const [selectedPlan, setSelectedPlan] = useState();
  const [selectedPackage, setSelectedPackage] = useState();
  const [selectedAddress, setSelectedAddress] = useState(undefined);
  const [animation, setAnimation] = useState(false);
  const [dialogShow, setDialogShow] = useState(true);
  const [privacyOptIn, setPrivacyOptIn] = useState(false);
  const [defaultFormData, setDefaultFormData] = useState({});
  const { userIdle, setUserIdle } = useContext(ActivityContext);
  const modal = useRef(null);
  const screenSize = useScreenSize();

  let formCookie, addressCookie;
  const cookieName = 'litfibre-address';

  const newsletterFormFields =
    process.env.NODE_ENV === 'development' ||
    process.env.GATSBY_ENVIRONMENT === 'development'
      ? NewsletterFieldsUAT
      : NewsletterFieldsLive;

  useEffect(() => {
    formCookie = Cookies.get(NewsletterFormCookie);
    addressCookie = Cookies.get(cookieName);

    if (formCookie) {
      setDefaultFormData(JSON.parse(formCookie));
    }
    if (addressCookie) {
      let storedAddress = JSON.parse(atob(addressCookie));
      setSelectedAddress(storedAddress);
    }
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formDataObject = {};
    for (const input of event.target) {
      if (input.type === 'checkbox') formDataObject[input.name] = input.checked;
      else formDataObject[input.name] = input.value;
    }
    if (formDataObject) {
      setSubmitted(true);
      Cookies.set(NewsletterFormCookie, formDataObject);
      event.target.submit();
    }
  };

  const switchDialog = () => {
    const dialog = modal.current;
    setDialogShow((prevState) => !prevState);
    if (dialogShow) {
      dialog.showModal();
      setAnimation('open-modal');
    } else {
      setAnimation('close-modal');
      setTimeout(() => {
        dialog.close();
      }, 200);
    }
  };

  return (
    <div className="form-wrapper">
      {formHeading && !submitted && (
        <div className="form-heading">
          <LitStructuredText data={formHeading} basic />
        </div>
      )}
      {!defaultFormData.Email ? (
        <form
          className="form"
          onSubmit={handleSubmit}
          method="POST"
          action={newsletterFormFields?.url}
        >
          {/* <input type="hidden" name="debug" value="1" />
          <input
            type="hidden"
            name="debugEmail"
            value="harvey.jones@litfibre.com"
          /> */}
          <input
            type="hidden"
            name="retURL"
            defaultValue={`${process.env.GATSBY_BASE_ADDRESS}/newsletter-sign-up-success`}
          />
          {isPopUp && (
            <button
              onClick={() => setUserIdle(!userIdle)}
              className="form-heading"
            >
              {screenSize.width > 800 ? (
                <h3>Sign up to our newsletter</h3>
              ) : (
                <h3>Newsletter</h3>
              )}
            </button>
          )}
          <div className="form-input-container">
            <input
              type="text"
              hidden
              name={newsletterFormFields?.leadType}
              id={newsletterFormFields?.leadType}
              value="News Letter"
              defaultValue="News Letter"
            />
            <input
              id={newsletterFormFields?.oid}
              type="text"
              hidden
              name="oid"
              defaultValue={newsletterFormFields?.oid}
            />
            <input
              id={newsletterFormFields?.postcodeSearched}
              type="hidden"
              name={newsletterFormFields?.postcodeSearched}
              value={selectedAddress ? 1 : 0}
            />
            <input
              id={newsletterFormFields?.ga4SourceMedium}
              maxlength="255"
              name={newsletterFormFields?.ga4SourceMedium}
              size="20"
              type="text"
              hidden
            />
            <input
              type="text"
              hidden
              name={newsletterFormFields?.contactPreferences}
              value={'Email'}
              id={newsletterFormFields?.contactPreferences}
            />
            <input
              type="text"
              hidden
              name={newsletterFormFields?.uprnValue}
              id={newsletterFormFields?.uprnValue}
              defaultValue={selectedAddress?.uprn}
            />
            <div className="form-input-inner column-span">
              <label for={newsletterFormFields?.email}>Email</label>
              <input
                id={newsletterFormFields?.email}
                maxlength="80"
                required
                pattern="^.+@.+\.[a-zA-Z]{2,63}$"
                size="20"
                type="email"
                name={newsletterFormFields?.email}
                placeholder="Enter your email"
                defaultValue={defaultFormData?.Email}
                onChange={(event) => event.target.setCustomValidity('')}
              />
            </div>
            <div className="form-input-inner column-1">
              <label for={newsletterFormFields?.firstName}>First Name*</label>
              <input
                id={newsletterFormFields?.firstName}
                maxlength="40"
                name={newsletterFormFields?.firstName}
                size="20"
                type="text"
                required
                defaultValue={defaultFormData?.FirstName}
              />
            </div>
            <div className="form-input-inner column-3">
              <label for={newsletterFormFields?.lastName}>Last Name</label>
              <input
                id={newsletterFormFields?.lastName}
                maxlength="80"
                name={newsletterFormFields?.lastName}
                size="20"
                type="text"
                defaultValue={defaultFormData?.LastName}
              />
            </div>
          </div>
          <div className="form-checkbox-container column-span">
            <input
              className="checkbox"
              type="checkbox"
              id={newsletterFormFields?.marketingCheckbox}
              required
              defaultValue="0"
              value={privacyOptIn ? 1 : 0}
              name={newsletterFormFields?.marketingCheckbox}
              onChange={(e) => {
                if (e.target.checked) {
                  setPrivacyOptIn(true);
                } else {
                  setPrivacyOptIn(false);
                }
              }}
            />
            <p>
              By providing my details. I consent to Lit Fibre processing and
              storing this information in accordance with it’s{' '}
              <button onClick={switchDialog}>Privacy Policy</button> and using
              these details to send me information about Lit Fibre’s services. I
              understand that I may change my mind and unsubscribe at any time.{' '}
            </p>
            <Modal
              animation={animation}
              dialogShow={dialogShow}
              modal={modal}
              switchDialog={switchDialog}
            >
              <div className="modal-simple-website">
                <SimplePagePopUp
                  simpleModularContent={
                    legalTermsContent[0].terms.simpleModularContent
                  }
                  title={legalTermsContent[0].terms.title}
                  seo={legalTermsContent[0].terms.seo}
                  slug={legalTermsContent[0].terms.slug}
                />
              </div>
            </Modal>
          </div>
          <div className="form-single-button-container">
            <button type="submit" className="button-blue">
              Submit
            </button>
          </div>
        </form>
      ) : (
        <div className={`form-submitted ${isPopUp && 'Black'}`}>
          {isPopUp ? (
            <ProductInfo
              header="Thank
             you for signing up"
              subheader={'See if you can get Lit today'}
              newsletterPopUp={true}
              selectedAddress={selectedAddress}
              setSelectedAddress={setSelectedAddress}
              backgroundColor="Black"
              isProductPagePath={isProductPagePath}
              setSelectedPlan={setSelectedPlan}
              selectedPlan={selectedPlan}
              setSelectedPackage={setSelectedPackage}
              selectedPackage={selectedPackage}
            />
          ) : (
            <div className="form-wrapper">
              <div className="form">
                <div className="form-submitted">
                  <h5>Thank you for signing up!</h5>
                  <p>
                    Keep an eye on your inbox for our next newsletter and don't
                    forget to add{' '}
                    <a href="webmarketing@litfibre.com">
                      webmarketing@litfibre.com
                    </a>{' '}
                    to your contacts
                  </p>
                  <div className="form-single-button-container">
                    <Link
                      className="button"
                      to="/secure-order/contact-form-page"
                    >
                      Check if you can get Lit
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default NewsletterForm;
