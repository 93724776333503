import React, { useState, useContext, useRef, useEffect } from 'react';
import RegisterInterestForm from './RegisterInterestForm';
import Modal from './Modal';
import { graphql, useStaticQuery } from 'gatsby';
import { useLocation } from '@gatsbyjs/reach-router';
import ActivityContext from '../context/ActivityContext';
import flagService from '../services/FlagService';
const RegisterInterestModal = ({ isButton, isLink, text, pathColour }) => {
  const { userIdle, setUserIdle } = useContext(ActivityContext);
  const [dialogShow, setDialogShow] = useState(true);
  const [animation, setAnimation] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(undefined);
  const [showRiForm, setShowRiForm] = useState(false);
  const modal = useRef(null);
  const data = query();
  const location = useLocation();
  const inBuildTown = location.state?.inBuildTown;
  const networkCoverage = data.allDatoCmsNetworkCoverageModel.nodes.find(
    (coverage) =>
      coverage.areaName &&
      inBuildTown &&
      townNamesMatch(coverage.areaName, inBuildTown),
  );
  const region = networkCoverage?.region;
  const dialog = modal.current;
  const registerInterestIdleTimer = flagService.RegisterInterestPopup;

  const switchDialog = () => {
    setDialogShow((prevState) => !prevState);
    if (dialogShow) {
      dialog?.showModal();
      setAnimation('open-modal');
      setUserIdle(false);
    } else {
      setAnimation('close-modal');
      setShowRiForm(false);
      setTimeout(() => {
        dialog?.close();
      }, 200);
      setUserIdle(false);
    }
  };
  useEffect(() => {
    setUserIdle(false);
  }, [location]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDialogShow(true);
      dialog?.showModal();
      setAnimation('open-modal');
    }, 45000);
    return () => clearTimeout(timer);
  }, [dialog]);

  useEffect(() => {
    if (userIdle && registerInterestIdleTimer) {
      setDialogShow(true);
      dialog?.showModal();
      setAnimation('open-modal');
    }
  }, [userIdle]);

  return (
    <div
      className="register-interest-modal-container"
      style={{ display: `${isLink && 'inline'}` }}
    >
      <Modal
        animation={animation}
        dialogShow={dialogShow}
        modal={modal}
        switchDialog={switchDialog}
        setUserIdle={setUserIdle}
        userIdle={userIdle}
      >
        <div className="White">
          {!showRiForm ? (
            <div className="register-interest-modal-no-form-container">
              <div className="modular-heading-secondary modular-p text-center">
                <h3>Interested but not ready to buy just yet?</h3>
                <p>Leave your details and we can stay in touch</p>
              </div>
              <button
                onClick={() => setShowRiForm(true)}
                type="button"
                className={`${isButton && `square-button-${pathColour}`} ${
                  isLink && `${pathColour}-button-underline`
                } ${pathColour} path`}
              >
                {text}
              </button>
            </div>
          ) : (
            <div className="register-interest-modal-form-container">
              <div className="modular-heading-secondary">
                <h3>Register your interest</h3>
              </div>
              <RegisterInterestForm
                selectedAddress={selectedAddress}
                setSelectedAddress={setSelectedAddress}
                region={region}
                isModularBlock={true}
                successPath={location.pathname.replace(/^\/+/g, '')}
              />
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default RegisterInterestModal;
export const query = () =>
  useStaticQuery(graphql`
    {
      allDatoCmsNetworkCoverageModel {
        nodes {
          inBuild
          areaName
          available
          region
        }
      }
    }
  `);
